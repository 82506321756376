import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c8c6dfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hobbie-state" }
const _hoisted_2 = { class: "hobbie-img" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["icon hobbie-icon", 'icon-' + _ctx.icon])
      }, null, 2)
    ]),
    _createElementVNode("label", null, _toDisplayString(_ctx.name), 1)
  ]))
}