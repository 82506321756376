import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-311d8064"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = { class: "progress-bar-header" }
const _hoisted_3 = { class: "progress-bar-externe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1),
      _createElementVNode("h3", null, _toDisplayString(_ctx.progressList[_ctx.value - 1]), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "progress-bar-interne",
        style: _normalizeStyle({ width: _ctx.initLevel * 20 + '%' })
      }, null, 4)
    ])
  ]))
}